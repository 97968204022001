import React, { Component } from "react"
import { navigate } from "gatsby"
import Form from "react-bootstrap/Form"
//import ReactDOM from "react-dom"

export default class Home extends React.Component {
  componentDidMount() {
    window.location.replace("https://www.arcgis.com/apps/webappviewer/index.html?id=2ff1677111ae4018ac705fcce7c3312f")
  }

  render() {
    return (
      <div />
    )
  }
}
